<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :eliminar="false"></nav-form>
    <section>
      <article style="height: 70px">
        <field
          label="ID"
          inline-label
          name="id"
          widget="char"
          searchable
          width="67px"
          style="top: 10px; left: 10px"
          readonly
        />
        <field
          label="Acción"
          inline-label
          name="action"
          widget="select"
          :options="{
            Leer: 'read',
            Actualizar: 'update',
            Crear: 'create',
            Eliminar: 'delete',
          }"
          searchable
          width="120px"
          style="top: 10px; left: 100px"
          readonly
        />
        <field
          label="Formulario"
          inline-label
          name="model"
          widget="char"
          searchable
          width="120px"
          style="top: 10px; left: 275px"
          readonly
        />
        <field
          label="Usuario"
          inline-label
          name="user_id"
          widget="m2o"
          primary="id"
          searchable
          width="150px"
          style="top: 10px; left: 465px"
          readonly
        />
        <field
          label="IP"
          inline-label
          name="ip"
          widget="char"
          searchable
          width="120px"
          style="top: 40px; left: 12px"
          readonly
        />
        <field
          label="Localización"
          inline-label
          name="location"
          widget="char"
          searchable
          width="120px"
          style="top: 40px; left: 160px"
          readonly
        />
        <field
          label="Fecha"
          inline-label
          name="create_date"
          type="date"
          searchable
          width="100px"
          style="top: 40px; left: 355px"
          readonly
        />
        <field
          label="Info"
          inline-label
          name="payload"
          searchable
          width="132px"
          style="top: 40px; left: 500px"
          readonly
        />
      </article>
    </section>
    <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
  </div>
</template>
<style>
</style>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Historial",
      dbAdapter: "log",
      primary: "id",
      log: false,
      orderDir: "DESC",
      additionalHtSettings: {
        height: 330,
        minRows: 14,
      },
    };
  },
};
</script>